import React, { useEffect, useState } from 'react';
import { SignInEmail } from '../SignInEmail';
import { SignInGoogle } from '../SignInGoogle';
import { SignInApple } from '../SignInApple';

import './SignIn.scss';

export const SignIn = () => {
  const [ error, setError ] = useState(null);

  return (
    <div className="SignIn">
      <div className="SignIn__grid">
        <div className="SignIn__icon">
        </div>
        <h1 className="SignIn__title">
          Reading becomes <br /> <span className="SignIn__title--accent">fun</span> and <span className="SignIn__title--accent">easy</span>
        </h1>
        <p className="SignIn__text">Login to personalize your experience and track your progress</p>
        
        <SignInEmail errorState={{error, setError}} />

        {error && 
          <div className="SignIn__error-wrapper">
            <p className="SignIn__error">{error.message}</p>
          </div>
        }
        <p className="SignIn__text--login-choice">or with</p>
        
        <SignInGoogle errorSetter={setError} />
        <SignInApple errorSetter={setError} />
      </div>
    </div>
  );
}
