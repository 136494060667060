import './PageDivider.scss';

export const PageDivider = ({ number, total }) => {
  return (
    <div className="PageDivider">
      <div className="PageDivider__line"></div>
      <p className="PageDivider__text">
        {`${number} of ${total}`}
      </p>
      <div className="PageDivider__line"></div>
    </div>
  );
}
