import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { BooksContext } from '../../context/BooksContext';
import className from 'classnames';
import * as ROUTES from '../../constants/routes';

import './BookPreview.scss';

export const BookPreview = ({ book }) => {
  const { setCurrentBook } = useContext(BooksContext);
  const history = useHistory();
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setCurrentBook((prev) => ({ ...prev, book }));
    history.push(ROUTES.BOOK_OVERVIEW);
  }

  const handleImageLoad = () => {
    setImageLoaded(true);
  }

  return (
    <div className="BookPreview" onClick={handleClick}>
      <div className="BookPreview__placeholder">
        <img
          className={className("BookPreview__image", { "BookPreview__image-loaded": imageLoaded })}
          src={book.image}
          onLoad={handleImageLoad}
        ></img>
      </div>
      <div className="BookPreview__author-wrapper">
        <p className="BookPreview__author">{book.author}</p>
        <div className="BookPreview__gradient-block"></div>
      </div>
    </div>
  );
}
