import React, { createContext, useCallback, useContext } from "react";

const Context = createContext({
  trackEvent: () => {},
  setUserProperties: () => {},
  setUserId: () => {},
});

export const AnalyticsProvider = ({ children, services }) => {
  const trackEvent = useCallback(
    (event, data) => {
      services.forEach((service) => service.trackEvent(event, data));
    },
    [...services]
  );

  const setUserProperties = useCallback(
    (properties) => {
      services.forEach((service) => service.setUserProperties(properties));
    },
    [...services]
  );

  const setUserId = useCallback(
    (id) => {
      services.forEach((service) => service.setUserId(id));
    },
    [...services]
  );

  return (
    <Context.Provider value={{ trackEvent, setUserProperties, setUserId }}>
      {children}
    </Context.Provider>
  );
};

export function useAnalytics() {
  const analytics = useContext(Context);

  return { ...analytics };
}

export function useTrackEvent() {
  const { trackEvent } = useAnalytics();

  return trackEvent;
}
