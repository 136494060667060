import { useCallback, useEffect, useState } from 'react';

import './SearchBar.scss';

const debounce = (f, delay) => {
  let timerId;

  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(f, delay, ...args);
  };
}

export const SearchBar = ({ setSearchQuery, handleClickSearch }) => {
  const [query, setQuery] = useState('');

  const applyQuery = useCallback(
    debounce(setSearchQuery, 1000),
    []
  );

  const handleInput = (e) => {
    console.log('query', e.target.value)
    setQuery(e.target.value);
    applyQuery(e.target.value);
  }

  useEffect(() => {
    return () => {
      setSearchQuery('');
    };
  }, []);

  return (
    <div className="SearchBar">
      <div className="SearchBar__input-wrapper">
        <input
          type="search"
          className="SearchBar__input"
          value={query}
          placeholder="Type book title or author"
          onChange={handleInput}
        />
        <div
          className="SearchBar__icon-back"
          onClick={handleClickSearch}
        ></div>
      </div>
    </div>
  );
}
