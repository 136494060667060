import { withFirebase } from '../Firebase';

import './SignOut.scss';

const SignOutBase = ({ firebase, children }) => {
  const handleClick = (e) => {
    e.preventDefault();

    firebase.doSignOut();
  }

  return (
    <div className="SignOut">
      <p className="SignOut__text" onClick={handleClick}>{children}</p>
    </div>
  );
}

export const SignOut = withFirebase(SignOutBase);
