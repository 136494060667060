import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';

import { withAuthorization } from '../Session';
import { AuthUserContext } from '../../context/AuthUserContext';
import { SignOut } from '../SignOut/SignOut';

import * as ROUTES from '../../constants/routes';
import './Settings.scss';


const SettingsBase = () => {
  const authUser = useContext(AuthUserContext);
  const history = useHistory();

  const handleArrowButton = () => {
    history.push(ROUTES.DISCOVER);
  }

  return (
    <div className="Settings">
      <div className="Settings__header">
        <div className="Settings__header-content">
          <div
            className="Settings__icon-back"
            onClick={handleArrowButton}
          ></div>
          <h6 className="Settings__title">Settings</h6>
        </div>
      </div>
      <div className="Settings__grid">
        <a
          className="Settings__link"
          href="https://policy.get-headway.com/info/privacy-policy-web.html"
        >
          Privacy policy
        </a>
        <a
          className="Settings__link"
          href="https://policy.get-headway.com/info/terms-and-conditions-web.html"
        >
          Terms of use
        </a>
        <div className="Settings__logout">
          <SignOut>
            Log out
          </SignOut>
          <p className="Settings__logout-email">{authUser.email}</p>
        </div>
      </div>
      <div className="Settings__footer">
        <div className="Settings__stores">
          <a
            className="Settings__google-wrapper"
            href="https://play.google.com/store/apps/details?id=com.headway.books&hl=en&gl=US"
          >
            <div className="Settings__google-play"></div>
          </a>
          <a
            className="Settings__apple-wrapper"
            href="https://apps.apple.com/us/app/headway-self-growth-challenge/id1457185832"
          >
            <div className="Settings__app-store"></div>
          </a>
        </div>
      </div>
    </div>
  );
}

const condition = authUser => !!authUser;

export const Settings = withAuthorization(condition)(SettingsBase);
