export const AUTH_INTENT = 'auth_intent';
export const AUTH_SUCCESS = 'auth_success';
export const AUTH_ERROR = 'auth_error';
export const DISCOVER_VIEW = 'discover_view';
export const OVERVIEW_VIEW = 'overview_view';
export const SUMMARY_TEXT_VIEW = 'summary_text_view';
export const SUMMARY_START = 'summary_start';
export const SUMMARY_LAST_PAGE = 'summary_last_page';
export const SEARCH_RESULT = 'search_result';
export const SUBSCRIPTION_INTENT = 'subscription_intent';
