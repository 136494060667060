import { addEventPrefix } from '../../utils/helpers';

export default class AmplitudeService {
  client;

  constructor(
    apiKey,
    userId,
    config,
    instanceName,
  ) {
    this.client = require('amplitude-js').getInstance(instanceName);
    this.client?.init(apiKey, userId, config);
  }

  trackEvent(event, data, callback) {
    const eventWithPrefix = addEventPrefix(event);
    
    this.client?.logEvent(eventWithPrefix, data, callback);
  }

  setUserProperties(properties) {
    return this.client?.setUserProperties(properties);
  }

  setUserId(id) {
    return this.client?.setUserId(id);
  }
}
