import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { BookSummaryPage } from '../BookSummaryPage';
import { PageDivider } from '../PageDivider';
import { LoadingAnimation } from '../LoadingAnimation';
import { Error } from '../Error';

import { AuthUserContext } from '../../context/AuthUserContext';
import { FirebaseContext } from '../../context/FirebaseContext';
import { BooksContext } from '../../context/BooksContext';
import { withAuthorization } from '../Session';
import { useAnalytics } from '../../providers/analytics';
import { SUMMARY_START, SUMMARY_LAST_PAGE } from '../../providers/analytics/events';

import * as ROUTES from '../../constants/routes';
import './BookSummary.scss';

const BookSummaryBase = () => {
  const { currentBook, setCurrentBook } = useContext(BooksContext);
  const { isSubscribed } = useContext(AuthUserContext);
  const firebase = useContext(FirebaseContext);
  const history = useHistory();

  const [summary, setSummary] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [progressBar, setProgressBar] = useState(0);

  const { trackEvent } = useAnalytics();

  // Fetch summary from Firebase
  const fetchSummary = () => {
    if (currentBook.summary && !!currentBook.summary.length) {
      setSummary(currentBook.summary);
      setIsLoading(false);
      setIsError(false);
      return;
    }

    firebase.getSummary(currentBook.book.id)
      .then((snapshot) => {
        const data = snapshot.docs.map(doc => doc.data());

        setSummary(data);

        setIsLoading(false);

        if(data.length === 0) {
          setIsError(false);
        }

        setCurrentBook(prev => ({ ...prev, summary: data }));
      })
      .catch((error) => {
        console.log(error);

        setIsError(true);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    trackEvent(SUMMARY_START, {
      book_id: currentBook.book.id,
      book_name: currentBook.book.title,
      format: 'text',
    });
  }, []);

  useEffect(() => {
    if(progressBar === 100) {
      trackEvent(SUMMARY_LAST_PAGE, {
        book_id: currentBook.book.id,
        book_name: currentBook.book.title,
        format: 'text',
      });
    }
  }, [progressBar])

  useEffect(() => {
    if(!currentBook.book || !isSubscribed) {
      history.push(ROUTES.DISCOVER);
    } else {
      fetchSummary();
    }

    return () => {
      setCurrentBook(prev => ({ ...prev, summary: null }));
    }
  }, []);

  // Progress bar dependency
  useEffect(() => {
    const onScroll = () => {
      const offset = window.pageYOffset;
      const height = document.body.scrollHeight - document.body.clientHeight;

      setProgressBar(offset / height * 100);
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // Handling "Back" button
  const handleClick = () => {
    history.push(ROUTES.BOOK_OVERVIEW);
  }

  return (
    <div className="BookSummary">
      <div className="BookSummary__header">
        <div className="BookSummary__header-content">
          <div className="BookSummary__icon-back" onClick={handleClick}></div>
          <h6 className="BookSummary__title">{currentBook.book.title}</h6>
          <div className="BookSummary__gradient-block"></div>
        </div>
      </div>
      <div className="BookSummary__background"></div>
      <div
        className="BookSummary__progress-bar"
        style={{background: `linear-gradient(to right, rgb(0, 102, 255) ${progressBar}%, transparent 0px)`}}
      ></div>
      {isLoading &&
        <LoadingAnimation />
      }
      {isError &&
        <Error
          text={"Something went wrong..."}
          secondaryText={"Try to refresh the page"}
        />
      }
      <div className="BookSummary__grid">
        {!isLoading && !isError && 
          <>
            {
              summary[0].pages.map((page, index) => (
              <React.Fragment key={`page-${index}`}>
                <BookSummaryPage page={page}  />
                {(index !== summary[0].pages.length - 1) &&
                  <PageDivider number={index + 2} total={summary[0].pages.length} />
                }
              </React.Fragment>
            ))}
          </>
        }
      </div>
    </div>
  );
}

const condition = authUser => !!authUser;

export const BookSummary = withAuthorization(condition)(BookSummaryBase);

