import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './components/App';
import { Firebase } from './components/Firebase';
import { FirebaseContext } from './context/FirebaseContext';
import AmplitudeService from './providers/analytics/Amplitude';
import { AnalyticsProvider } from './providers/analytics';

import 'normalize.css';
import './index.scss';

const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
console.log(amplitudeApiKey);

const firebaseInstance = new Firebase();
const analyticsServices = [new AmplitudeService(amplitudeApiKey)];

ReactDOM.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={firebaseInstance}>
      <AnalyticsProvider services={analyticsServices}>
        <App />
      </AnalyticsProvider>
    </FirebaseContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
