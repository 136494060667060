import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import ProgressBar from "react-progressbar-on-scroll";
import Fuse from "fuse.js";

import { FirebaseContext } from "../../context/FirebaseContext";
import { BooksContext } from "../../context/BooksContext";
import { withAuthorization } from "../Session";

import { BooksList } from "../BooksList";
import { SearchBar } from "../SearchBar";
import { LoadingAnimation } from "../LoadingAnimation";
import { Error } from "../Error";
import { NoSubscription } from "../NoSubscription";

import * as ROUTES from "../../constants/routes";
import "./Discover.scss";
import { AuthUserContext } from "../../context/AuthUserContext";
import { useAnalytics } from "../../providers/analytics";
import { DISCOVER_VIEW, SEARCH_RESULT } from "../../providers/analytics/events";

const searchParameters = {
  keys: [
    {
      name: "title",
      weight: 5,
    },
    "author",
    "overview",
    "keywords",
  ],
};

const DiscoverBase = () => {
  const [error, setError] = useState(false);
  const [filteredBooks, setFilteredBooks] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [fuse, setFuse] = useState(null);

  const [header, setHeader] = useState({ big: true, search: false });
  const [scrollTop, setScrollTop] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [showNoSub, setShowNoSub] = useState(false);

  const history = useHistory();

  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);

  const { books, setBooks } = useContext(BooksContext);

  const { trackEvent } = useAnalytics();

  const fetchBooks = () => {
    firebase
      .getBooks()
      .then((snapshot) => {
        const data = snapshot.docs
          .map((doc) => doc.data())
          .filter((item) => item.enabled === true);

        console.log(data);

        setBooks(data);
        setFilteredBooks(data);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    trackEvent(DISCOVER_VIEW);
  }, []);

  useEffect(() => {
    if (authUser.isSubscribed) {
      fetchBooks();
    } else {
      setIsLoading(false);
      setShowNoSub(true);
    }
  }, []);

  useEffect(() => {
    if (books) {
      const fuseInstance = new Fuse(books, searchParameters);

      setFuse(fuseInstance);
    }
  }, [books]);

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredBooks(books);
    } else {
      const filtered = fuse.search(searchQuery);
      console.log(filtered);
      setFilteredBooks(filtered.map((book) => book.item));
      trackEvent(SEARCH_RESULT, {
        query: searchQuery,
        results_count: filtered.length,
      });
    }
  }, [searchQuery]);

  // Checking scroll for header size change
  useEffect(() => {
    const onScroll = () => {
      const offset = window.pageYOffset;

      if (offset > 20) {
        setHeader((prev) => ({ ...prev, big: false }));
      } else {
        setHeader((prev) => ({ ...prev, big: true }));
      }

      setScrollTop(window.pageYOffset);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const handleClickDiscover = () => {
    setHeader((prev) => ({ ...prev, search: true }));
  };

  const handleClickSearch = () => {
    setHeader((prev) => ({ ...prev, search: false }));
  };

  const handleSettingsButton = () => {
    history.push(ROUTES.SETTINGS);
  };

  return (
    <div className="Discover">
      <div className="Discover__background"></div>
      {!header.search && (
        <div className={"Discover__header" + (header.big ? "" : "--small")}>
          <div className="Discover__title-wrapper">
            <h5 className={"Discover__title" + (header.big ? "" : "--small")}>
              Discover
            </h5>
            {!error && authUser.isSubscribed && (
              <div
                className="Discover__icon-search"
                onClick={handleClickDiscover}
              ></div>
            )}
          </div>
        </div>
      )}
      {header.search && (
        <SearchBar
          setSearchQuery={setSearchQuery}
          handleClickSearch={handleClickSearch}
        />
      )}
      {isLoading && <LoadingAnimation />}
      {showNoSub && <NoSubscription />}
      {error && (
        <Error
          text={"Something went wrong..."}
          secondaryText={"Try to refresh the page"}
        />
      )}
      {!!filteredBooks && authUser.isSubscribed && (
        <BooksList books={filteredBooks} setIsLoading={setIsLoading} />
      )}
      <button
        className="Discover__button-open-settings"
        onClick={handleSettingsButton}
      >
        <div className="Discover__icon-open-settings"></div>
      </button>
      <ProgressBar
        color="#0066FF"
        height={4}
        direction="left"
        position="bottom"
        gradient={false}
      />
    </div>
  );
};

const condition = (authUser) => !!authUser;

export const Discover = withAuthorization(condition)(DiscoverBase);
