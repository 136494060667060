import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import { useAnalytics } from "../../providers/analytics";
import {
  AUTH_INTENT,
  AUTH_ERROR,
  AUTH_SUCCESS,
} from "../../providers/analytics/events";
import * as ROUTES from "../../constants/routes";
import classNames from "classnames";

import "./SignInEmail.scss";

const SignInEmailBase = (props) => {
  const { error, setError } = props.errorState;

  const history = useHistory();
  const { trackEvent } = useAnalytics();

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8) {
      errors.password = "Password should be at least 8 symbols long";
    }

    return errors;
  };

  const signIn = (emailValue, passwordValue) => {
    trackEvent(AUTH_INTENT, { provider: 'email' });
    props.firebase
      .doSignInWithEmailAndPassword(emailValue, passwordValue)
      .then(() => {
        trackEvent(AUTH_SUCCESS, { provider: 'email' });
        history.push(ROUTES.DISCOVER);
      })
      .catch((error) => {
        trackEvent(AUTH_ERROR, {
          provider: 'email',
          code: error.code,
          message: error.message,
        });
        setError(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      signIn(values.email, values.password);
    },
  });

  const emailError = formik.errors.email && formik.touched.email;
  const passwordError = formik.errors.password && formik.touched.password;

  return (
    <div className="SignInEmail">
      <h3 className="SignInEmail__title">Login with email</h3>
      <form className="SignInEmail__form" onSubmit={formik.handleSubmit}>
        <div className="SignInEmail__input-group">
          {emailError ? (
            <p className="SignInEmail__error-message">{formik.errors.email}</p>
          ) : null}
          <div className="SignInEmail__input-wrapper">
            <input
              name="email"
              className={classNames("SignInEmail__input", {
                "SignInEmail__input-error": emailError,
              })}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="email"
              placeholder="Email"
            />
            <div
              className={classNames(
                "SignInEmail__input-icon SignInEmail__input-icon--email",
                {
                  "SignInEmail__input-icon--email-error": emailError,
                }
              )}
            ></div>
          </div>
        </div>
        <div className="SignInEmail__input-group">
          {passwordError ? (
            <p className="SignInEmail__error-message">
              {formik.errors.password}
            </p>
          ) : null}
          <div className="SignInEmail__input-wrapper">
            <input
              name="password"
              className={classNames("SignInEmail__input", {
                "SignInEmail__input-error": passwordError,
              })}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="password"
              placeholder="Password"
            />
            <div
              className={classNames(
                "SignInEmail__input-icon SignInEmail__input-icon--password",
                {
                  "SignInEmail__input-icon--password-error": passwordError,
                }
              )}
            ></div>
            <div className="SignInEmail__input-icon SignInEmail__input-icon--eye"></div>
          </div>
        </div>
        <p className="SignInEmail__agreement">
          By continuing I agree with
          <br />
          the&nbsp;
          <a
            className="SignInEmail__agreement-link"
            href="https://policy.get-headway.com/info/privacy-policy-web.html"
          >
            Privacy Policy
          </a>
          ,&nbsp;
          <a
            className="SignInEmail__agreement-link"
            href="https://policy.get-headway.com/info/terms-and-conditions-web.html"
          >
            Terms &#38; Conditions
          </a>
        </p>
        <input
          type="submit"
          className={classNames("SignInEmail__button", {
            "SignInEmail__button--disabled": !(formik.isValid && formik.dirty),
          })}
          disabled={!(formik.isValid && formik.dirty)}
          value="Continue"
        />
      </form>
    </div>
  );
};

export const SignInEmail = compose(withFirebase)(SignInEmailBase);
