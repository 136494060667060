import './Error.scss';

export const Error = ({ text, secondaryText, children}) => {
  return (
    <div className="Error">
      <div className="Error__illustration"></div>
      <p className="Error__text">{text}</p>
      <p className="Error__text-secondary">{secondaryText}</p>
      {children}
    </div>
  );
}
