import './BookSummaryPage.scss';

const chooseItemByType = (item, index) => {
  switch (item.type) {
    case 'TITLE':
      return (
        <div className="BookSummaryPage__title-wrapper" key={index}>
          <h5 className="BookSummaryPage__title">
            {item.content}
          </h5>
        </div>
      );
    case 'TEXT':
      return (
        <div className="BookSummaryPage__text-wrapper" key={index}>
          <p className="BookSummaryPage__text" dangerouslySetInnerHTML={{__html: item.content}}>
            {/* {item.content.replaceAll("<br>", "\n")} */}
          </p>
        </div>
      );
    case 'INSIGHT':
      return (
        <div className="BookSummaryPage__insight-wrapper" key={index}>
          <div className="BookSummaryPage__insight-icon"></div>
          <p className="BookSummaryPage__insight">
            {item.content}
          </p>
        </div>
      );
    case 'QUOTE':
      const data = item.content.split(' ~ ');

      return (
        <div className="BookSummaryPage__quote-wrapper" key={index}>
          <div className="BookSummaryPage__quote-icon"></div>
          <p className="BookSummaryPage__quote">{data[0].replace(/['"“”]+/g, '')}</p>
          <p className="BookSummaryPage__quote-author">{data[1]}</p>
        </div>
      );
  }
}

export const BookSummaryPage = ({ page }) => {
  return (
    <div className="BookSummaryPage">
      {page.items.map((item, index) => chooseItemByType(item, index))}
    </div>
  );
}
