import { useState } from 'react';
import { useHistory } from 'react-router';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { useAnalytics } from "../../providers/analytics";
import {
  AUTH_INTENT,
  AUTH_ERROR,
  AUTH_SUCCESS,
} from "../../providers/analytics/events";
import * as ROUTES from '../../constants/routes';

import './SignInGoogle.scss';

const SignInGoogleBase = (props) => {
  const history = useHistory();
  const { trackEvent } = useAnalytics();

  const signIn = () => {
    trackEvent(AUTH_INTENT, { provider: 'google' });
    props.firebase.doSignInWithGoogle()
      .then(() => {
        trackEvent(AUTH_SUCCESS, { provider: 'google' });
        history.push(ROUTES.DISCOVER);
      })
      .catch(error => {
        trackEvent(AUTH_ERROR, {
          provider: 'google',
          code: error.code,
          message: error.message,
        });
        props.errorSetter(error);
      })
  }

  const onSubmit = e => {
    e.preventDefault();

    signIn();
  }

  return (
    <div className="SignInGoogle">
      <form
        className="SignInGoogle__form"
        onSubmit={onSubmit}
      >
        <button
          type="submit"
          className="SignInGoogle__button"
        >
          <div className="SignInGoogle__icon">
          </div>
          <p className="SignInGoogle__text">Google</p>
        </button>
      </form>
    </div>
  );
}

export const SignInGoogle = compose(
  withFirebase,
)(SignInGoogleBase);
