import { withFirebase } from '../Firebase';
import './NoSubscription.scss';

import { useAnalytics } from '../../providers/analytics';
import { SUBSCRIPTION_INTENT } from '../../providers/analytics/events';

const NoSubscriptionBase = ({ firebase }) => {
  const { trackEvent } = useAnalytics();

  const handleClick = (e) => {
    e.preventDefault();

    firebase.doSignOut();
  }

  const handleSubscribe = () => {
    trackEvent(SUBSCRIPTION_INTENT);
    window.open("https://product.get-headway.com/app/store?splitId=3&utm_medium=web_app", "_blank");
  }

  return (
    <div className="NoSubscription">
      <p className="NoSubscription__text">
        Buy subscription to unlock books
      </p>
      <a
        className="NoSubscription__button-subscription"
        href=""
        onClick={handleSubscribe}
      >Buy subscription</a>
      <a
        className="NoSubscription__button-logout"
        href=""
        onClick={handleClick}
      >I already have a subscription</a>
    </div>
  );
}

export const NoSubscription = withFirebase(NoSubscriptionBase);
