import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { BookPreview } from '../BookPreview';

import './BooksList.scss';

export const BooksList = ({ books, setIsLoading }) => {
  const perPage = 30;

  const [allBooks, setAllBooks] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [lastPosition, setLastPosition] = useState(0);

  const [isFound, setIsFound] = useState(true);

  useEffect(() => {
    setIsLoading(false);
    setHasMore(true);
  
    if (books.length === 0) {
      setIsFound(false);
      setAllBooks([]);
      return;
    } else {
      setIsFound(true);
    }

    setAllBooks(books.slice(0, perPage));

    setLastPosition((prev) => prev + perPage);

    if (books.length - allBooks.length > 0) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [books]);

  const loadBooks = () => {
    setAllBooks((prev) =>{
      return [...prev, ...books.slice(lastPosition, lastPosition + perPage)]
    });

    setLastPosition((prev) => prev + perPage);

    if (books.length - allBooks.length > 0) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  };

  return (
    <div className="BooksList">
      {!isFound &&
        <div className="BooksList__no-books-wrapper">
          <div className="BooksList__no-books-icon"></div>
          <p className="BooksList__no-books-text">No books found</p>
        </div>
      }
      <InfiniteScroll
        dataLength={allBooks.length}
        next={loadBooks}
        hasMore={hasMore}
      >
        <div className="BooksList__grid">
          {
            allBooks.map(book => {
              return <BookPreview key={book.id} book={book} />
            })
          }
        </div>
      </InfiniteScroll>
    </div>
  );
}
