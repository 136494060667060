import React, { useContext, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router';
import { AuthUserContext } from '../../context/AuthUserContext';
import { BooksContext } from '../../context/BooksContext';
import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import { useAnalytics } from '../../providers/analytics';
import { OVERVIEW_VIEW } from '../../providers/analytics/events';

import './BookOverview.scss';

const BookOverviewBase = () => {
  const { currentBook } = useContext(BooksContext);
  const { isSubscribed } = useContext(AuthUserContext);
  const history = useHistory();
  const { trackEvent } = useAnalytics();

  console.log(currentBook);

  const handleCloseButton = () => {
    history.push(ROUTES.DISCOVER);
  }

  const handleReadButton = () => {
    history.push(ROUTES.BOOK_SUMMARY);
  }

  useEffect(() => {
    if(!currentBook.book) {
      history.push(ROUTES.DISCOVER);
    } else {
      trackEvent(OVERVIEW_VIEW, {
        book_id: currentBook.book.id,
        book_name: currentBook.book.title,
      });
    }
  }, []);

  if(!currentBook.book || !isSubscribed) {
    return (
      <Redirect to={ROUTES.DISCOVER} />
    );
  }

  const { title, author, keywords, image, chaptersCount, insightsCount, timeToRead, overview } = currentBook.book;

  return (
    <div className="BookOverview">
      <div className="BookOverview__grid">
        <div className="BookOverview__info">
          <img
            className="BookOverview__cover"
            src={image}
            alt={`Book cover: ${title}`}
          />
          <h5 className="BookOverview__book-title">{title}</h5>
          <p className="BookOverview__book-author">{author}</p>
          <div className="BookOverview__briefs">
            <div className="BookOverview__brief">
              <div className="BookOverview__brief-icon BookOverview__brief-icon--chapters"></div>
              <p className="BookOverview__brief-text">{`${chaptersCount} chapters`}</p>
            </div>
            <div className="BookOverview__brief BookOverview__brief--center">
              <div className="BookOverview__brief-icon BookOverview__brief-icon--time"></div>
              <p className="BookOverview__brief-text">{`${timeToRead} min`}</p>
            </div>
            <div className="BookOverview__brief">
              <div className="BookOverview__brief-icon BookOverview__brief-icon--insights"></div>
              <p className="BookOverview__brief-text">{`${insightsCount} insights`}</p>
            </div>
          </div>

          <button
            className="BookOverview__button-close"
            onClick={handleCloseButton}
          >
            <div className="BookOverview__icon-close"></div>
          </button>
        </div>
        <div className="BookOverview__divider"></div>
        <div className="BookOverview__keywords-wrapper">
          {keywords.map(keyword => (
            <div className="BookOverview__keyword" key={keyword}>
              <p className="BookOverview__keyword-text">
                {keyword}
              </p>
            </div>
          ))}
        </div>
        <div className="BookOverview__description-wrapper">
          <h6 className="BookOverview__description-title">Description</h6>
          <p className="BookOverview__description" dangerouslySetInnerHTML={{__html: overview}}></p>
        </div>
        <button
          className="BookOverview__button-read"
          onClick={handleReadButton}
        >
          <div className="BookOverview__icon-read"></div>
          <p className="BookOverview__icon-text">Read</p>
        </button>
      </div>
    </div>
  );
}

const condition = authUser => !!authUser;

export const BookOverview = withAuthorization(condition)(BookOverviewBase);
