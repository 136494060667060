import { useCallback, useEffect, useState } from 'react';
import {  BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { AuthUserContext } from '../../context/AuthUserContext';
import { BooksContext } from '../../context/BooksContext';

import { SignIn } from '../SignIn';
import { Discover } from '../Discover';
import { Settings } from '../Settings';
import { BookOverview } from '../BookOverview';
import { BookSummary } from '../BookSummary';

import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { useAnalytics } from '../../providers/analytics';

const AppBase = ({ firebase }) => {
  const [authUser, setAuthUser] = useState(null);

  const [books, setBooks] = useState(null);
  const [currentBook, setCurrentBook] = useState({ book: null, summary: null });

  const { setUserId } = useAnalytics();

  let listener = useCallback(() => {
    firebase.auth.onAuthStateChanged(
      user => {
        if(user) {
          firebase.getUserSubscriptionStatus(user.uid)
            .then((snapshot) => {
              setAuthUser({ ...user, isSubscribed: snapshot.data().subscriptionStatus.isActive});
              setUserId(user.uid);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setAuthUser(null);
          setUserId(null);
        }
      }
    );
  }, []);

  useEffect(() => {
    
  }, [authUser]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('HeadwayCurrentBook')) || { book: null, summary: null };
    setCurrentBook(data);
  }, []);

  useEffect(() => {
    localStorage.setItem('HeadwayCurrentBook', JSON.stringify(currentBook));
  }, [currentBook]);

  useEffect(() => {
    listener();

    return () => {
      listener = null;
    };
  }, []);

  return (
    <AuthUserContext.Provider value={authUser}>
      <BooksContext.Provider value={{ books, currentBook, setBooks, setCurrentBook}}>
        <Router basename="/">
          <Switch>
            <Route exact path={ROUTES.LANDING} component={SignIn} />
            <Route exact path={ROUTES.SIGN_IN} component={SignIn} />
            
            
              <Route exact path={ROUTES.DISCOVER} component={Discover} />
              <Route exact path={ROUTES.SETTINGS} component={Settings} />
              <Route exact path={ROUTES.BOOK_OVERVIEW} component={BookOverview} />
              <Route exact path={ROUTES.BOOK_SUMMARY} component={BookSummary} />

              <Route path="*" component={Discover} />
            
          </Switch>
        </Router>
      </BooksContext.Provider>
    </AuthUserContext.Provider>
  );
}

export const App = withFirebase(AppBase);
