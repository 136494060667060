import React from 'react';
import { useHistory } from 'react-router';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { useAnalytics } from '../../providers/analytics';
import {
  AUTH_INTENT,
  AUTH_ERROR,
  AUTH_SUCCESS,
} from "../../providers/analytics/events";
import * as ROUTES from '../../constants/routes';

import './SignInApple.scss';

const SignInAppleBase = (props) => {
  const history = useHistory();
  const { trackEvent } = useAnalytics();

  const signIn = () => {
    trackEvent(AUTH_INTENT, { provider: 'apple' });
    props.firebase.doSignInWithApple()
      .then(() => {
        trackEvent(AUTH_SUCCESS, { provider: 'apple' });
        history.push(ROUTES.DISCOVER);
      })
      .catch((error) => {
        trackEvent(AUTH_ERROR, {
          provider: 'apple',
          code: error.code,
          message: error.message,
        });
        props.errorSetter(error);
      })
  }

  const onSubmit = e => {
    e.preventDefault();

    signIn();
  }

  return (
    <div className="SignInApple">
      <form
        className="SignInApple__form"
        onSubmit={onSubmit}
      >
        <button
          type="submit"
          className="SignInApple__button"
        >
          <div className="SignInApple__icon">
          </div>
          <p className="SignInApple__text">Apple</p>
        </button>
      </form>
    </div>
  );
}

export const SignInApple = compose(
  withFirebase,
)(SignInAppleBase);
