import './LoadingAnimation.scss';

export const LoadingAnimation = () => {
  return (
    <div className="LoadingAnimation">
      <svg className="LoadingAnimation__spinner" viewBox="0 0 56 56">
        <circle className="LoadingAnimation__spinner-path" cx="28" cy="28" r="20" fill="none" strokeWidth="6"></circle>
      </svg>
    </div>
  );
}
