import app from 'firebase';
import 'firebase/auth';
import 'firebase/database';

require('dotenv').config();

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export class Firebase {
  auth;
  fs;
  googleProvider;

  subscriptionStatus;

  constructor() {
    app.initializeApp(firebaseConfig)
    
    this.auth = app.auth();
    this.fs = app.firestore();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.appleProvider = new app.auth.OAuthProvider('apple.com');
    this.appleProvider.addScope('email');
  }

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithApple = () =>
    this.auth.signInWithPopup(this.appleProvider);

  doSignOut = () =>
    this.auth.signOut();

  getUserSubscriptionStatus = (uid) =>
    this.fs.collection('users').doc(uid).get();

  getBooks = () =>
    this.fs.collection('books').get();

  getSummary = (id) =>
    this.fs.collection('books').doc(id).collection("summaryV4").get();
}
